
import { Options, Vue, Watch } from "vue-property-decorator";
import LivePayRegisterForm from "@/components/Pages/ThirdPartyPayment/LivePayRegisterForm.vue";
import { namespace } from "s-vuex-class";
import LoadAccountForm from "@/components/Pages/ThirdPartyPayment/LoadAccountForm.vue";
import AccountLoaded from "@/components/Pages/ThirdPartyPayment/AccountLoaded.vue";
import DefaultLayout from "@/layout/DefaultLayout.vue";

const ThirdPartyPaymentModule = namespace("third-party-payment");

@Options({
  components: {
    DefaultLayout,
    AccountLoaded,
    LoadAccountForm,
    LivePayRegisterForm,
  },
})
export default class ThirdPartyPayment extends Vue {
  @ThirdPartyPaymentModule.Action("getOne")
  public loadThirdPartyPaymentAction: any;

  @ThirdPartyPaymentModule.Getter("getSuccess")
  public livePaySuccess: any;

  @ThirdPartyPaymentModule.Getter("getDataItem")
  public thirdPartyPayment: any;

  @ThirdPartyPaymentModule.Getter("getMandateId")
  public livePayMandateId: any;

  private showView = "live-pay-register-form";

  public mounted(): void {
    this.loadThirdPartyPayment();
  }

  private loadThirdPartyPayment(): void {
    this.loadThirdPartyPaymentAction({
      resource: `live-pay/third-party-payment-request/${this.$route.query.token}`,
      hideSuccessMessage: true,
    });
  }

  @Watch("livePayMandateId", { immediate: true, deep: true })
  private onLivePayMandateIdChange(livePayMandateId: any): void {
    if (livePayMandateId) {
      this.showView = "load-account-form";
    }
  }

  @Watch("livePaySuccess", { immediate: true, deep: true })
  private onLivePaySuccessChange(livePaySuccess: any): void {
    if (true === livePaySuccess) {
      this.showView = "account-loaded";
    }
  }
}
